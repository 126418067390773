
export default [
    {
        path: '/flux',
        component: "__base",
        otherdata: { color: "ccompliance" },
        children: [
            {
                path: "project",
                component: "Project.vue",
                meta: { title: "Project List ", right: "fluxmanagement_project" },
            },
            {
                path: "analysis/:id",
                component: "Analysis.vue",
                meta: { title: "Analysis ", right: "fluxmanagement_project", submenu: ["tabs"] },
            },
            {
                path: "configuration/:id",
                component: "Configuration.vue",
                meta: { title: "Configuration", right: "fluxmanagement_project", submenu: ["tabs"]  },
            },
            {
                path: "users/:id",
                component: "Users.vue",
                meta: { title: "Users", right: "fluxmanagement_project", submenu: ["tabs"]  },
            },
            {
                path: "manage/chartofaccount",
                component: "Manage/ChartOfAccount.vue",
                meta: { title: "Manage Chart of Accounts" },
                // right: "fluxmanagement_master_chartofaccount"
            },

            {
                path: "*",
                component: "",
            },
        ],
    },
]

